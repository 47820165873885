<template>
	<div class="homeSet">
		<div class="homeSet-box">
			<div class="box-item">
				<div class="item-title">主题颜色</div>
				<el-radio-group v-model="style">
					<el-radio v-for="item in typeColumns" :key="item.id" :label="item.id">{{item.name}}</el-radio>
				</el-radio-group>
			</div>
		</div>
		<div class="homeSet-footer">
			<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				style: "orange",
				typeColumns: [{
						id: "orange",
						name: '橙色'
					},
					{
						id: "red",
						name: '红色'
					},
					{
						id: "blue",
						name: '蓝色'
					},
					{
						id: "green",
						name: '绿色'
					},
					{
						id: "purple",
						name: '紫色'
					}
				],
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/home/get_homepage_set`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.style = data.style
						this.typeColumns = data.styleList
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				this.$http.post(`/erp/v1/home/save_style`, {
					style: this.style,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.homeSet {
		.homeSet-box {
			background: #fff;
			border-radius: 6px;
			padding: 20px;
			margin-bottom: 20px;

			.box-item {
				display: flex;
				align-items: center;

				.item-title {
					width: 100px;
					font-size: 14px;
				}

				.item-b {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.homeSet-footer {
			display: flex;
			justify-content: center;

		}

	}
</style>